import * as XLSX from "xlsx";
import axios from "axios";
import ProgressBar from "@ramonak/react-progress-bar";
import { useState } from "react";
import { SyncLoader } from "react-spinners";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { BASE_URL } from "../data/data";

const AddQuestion = () => {
  const [questions, setQuestions] = useState([]);
  const [singleSubject, setSingleSubject] = useState("");
  const [completed, setCompleted] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false)

  const [question, setQuestion] = useState("");
  const [optionA, setOptionA] = useState("");
  const [optionB, setOptionB] = useState("");
  const [optionC, setOptionC] = useState("");
  const [optionD, setOptionD] = useState("");
  const [optionE, setOptionE] = useState("");
  const [answer, setAnswer] = useState("");
  const [difficulty, setDifficulty] = useState("Meduim")

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        setQuestions(json);
      };
      // setSubject(e.target.files[0].name.split(".")[0]);
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const reformat = (questionJson) => {
    const formatted = {};
    const options = [];
    Object.keys(questionJson).forEach((key) => {
      if (key.includes("ption"))
        options.push({ label: key[key.length - 1], value: questionJson[key] });
      else {
        formatted[key.toLocaleLowerCase()] = questionJson[key];
      }
    });
    formatted["options"] = options;
    return formatted;
  };

  const uploadQuestions = async () => {
    setUploading(true);
    try {
      questions.forEach(async (ques) => {
        const { question, answer, options, subject, difficulty } = reformat(ques);
        try {
          const res = await axios.post(BASE_URL+"question", {
            question,
            answer,
            options,
            subject,
            difficulty:"Meduim"
          });
          if (res.status === 201) setCompleted(completed + 1);
          else throw new Error("upload failed")
        } catch (error) {
          // new Error(error)
          console.log(error);
          enqueueSnackbar("Question Uploading Failed!!", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      });
      enqueueSnackbar("Questions Uploaded Successfully!!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setUploading(false);
    } catch (error) {
      enqueueSnackbar("Questions Uploading Failed!!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const uploadSingle = async () => {
    const questionData = {
      question,
      answer,
      subject: singleSubject,
      difficulty,
      options: [
        { label: "A", value: optionA },
        { label: "B", value: optionB },
        { label: "C", value: optionC },
        { label: "D", value: optionD },
        { label: "E", value: optionE },
      ],
    };
    try {
      setLoading(true)
      const res = await axios.post(BASE_URL + "question", questionData);
      if (res.status === 201) {
        setLoading(false)
        enqueueSnackbar("Question Uploaded Successfully!!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      };
    } catch (error) {
      console.log(error);
      setLoading(false)   
      enqueueSnackbar("Questions Uploading Failed!!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  // console.log(questions)
  return (
    <div className="unset flex flex-col max-sm:w-[90%] max-sm:ml-[10%]">
      <div>
        <SnackbarProvider />
      </div>
      <h2 className="mb-3 text-4xl text-blue-600">Upload Questions</h2>
      <div className="">
        <form>
          <label htmlFor="upload">Upload File</label>
          <input
            type="file"
            name="upload"
            id="upload"
            accept=".csv,.tsv,.xlsx"
            onChange={readUploadFile}
          />
        </form>
        <button
          disabled={uploading}
          onClick={uploadQuestions}
          className="p-2 mt-4 text-white bg-green-600 rounded-sm w-20 cursor-pointer shadow-sm shadow-black hover:bg-green-300"
        >
          Upload
        </button>
        <div className={uploading ? "flex flex-col gap-3 mt-3" : "hidden"}>
          <ProgressBar completed={completed} maxCompleted={questions.length} />
          <p>Uploading...</p>
        </div>
      </div>
      <div className="mt-6 flex flex-col">
        <h2 className="text-2xl mb-4 mt-4">Upload Single Question</h2>
        <label>Question:</label>
        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          className="question-inputs"
        />
        <label>Option A:</label>
        <input
          className="question-inputs"
          value={optionA}
          onChange={(e) => setOptionA(e.target.value)}
        />
        <label>Option B:</label>
        <input
          className="question-inputs"
          value={optionB}
          onChange={(e) => setOptionB(e.target.value)}
        />
        <label>Option C:</label>
        <input
          className="question-inputs"
          value={optionC}
          onChange={(e) => setOptionC(e.target.value)}
        />
        <label>Option D:</label>
        <input
          className="question-inputs"
          value={optionD}
          onChange={(e) => setOptionD(e.target.value)}
        />
        <label>Option E:</label>
        <input
          className="question-inputs"
          value={optionE}
          onChange={(e) => setOptionE(e.target.value)}
        />
        <label className="gap-2">
          Correct Answer:
          <select
            className="ml-2 border border-gray-400 rounded-md p-2"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          >
            <option>---Choose---</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
            <option value="E">E</option>
          </select>
        </label>
        <label className="gap-1 mt-3">
          Subject:
          <select
            className="ml-3 border border-gray-400 p-2 rounded-md"
            value={singleSubject}
            onChange={(e) => setSingleSubject(e.target.value)}
          >
            <option>---Choose---</option>
            <option value="English Language">English Language</option>
            <option value="Mathematics">Mathematics</option>
            <option value="Chemistry">Chemistry</option>
            <option value="Physics">Physics</option>
            <option value="General Knowledge">General Knowledge</option>
            <option value="Not Listed">Not Listed</option>
          </select>
        </label>
        <label className="gap-1 mt-3">
          Difficulty Level:
          <select
            className="ml-3 border border-gray-400 p-2 rounded-md"
            value={difficulty}
            onChange={(e) => setDifficulty(e.target.value)}
          >
            <option>---Choose---</option>
            <option value="Easy">Easy</option>
            <option value="Meduim">Meduim</option>
            <option value="Hard">Hard</option>
          </select>
        </label>
        <button
          disabled={loading}
          onClick={uploadSingle}
          className={`p-2 mt-4 rounded-sm cursor-pointer shadow-sm shadow-black text-white relative hover:bg-green-300 ${
            loading ? "bg-green-200" : "bg-green-500"
          }`}
        >
          {loading ? "Uploading..." : "Upload Single"}
          <div className="absolute top-0 right-16">
            <SyncLoader
              color={"blue"}
              loading={loading}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </button>
      </div>
    </div>
  );
};
export default AddQuestion;
