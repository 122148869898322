import axios from "axios"
import { SyncLoader } from "react-spinners"
import { useState } from "react"
import { BASE_URL } from "../data/data"
import { SnackbarProvider, enqueueSnackbar } from "notistack";

const ViewResults = () => {
  const [loading, setLoading] = useState(false);
  const [quizId, setQuizId] = useState("");
  const [results, setResults] = useState([])
  
  const getResults = async () => {
    setLoading(true)
    try {
      if (!quizId) {
        enqueueSnackbar("Name and Exam ID required!!", {
          variant: "error",
          autoHideDuration: 2000,
        });
      } else {
        const res = await axios.get(BASE_URL + `result/${quizId}`);
        if (res.status === 200) {
          const results = res.data
          setResults(results.results);
          setLoading(false)
        } else {
          throw new Error("Results fetching failed")
        }  
      }
    } catch (error) {
      console.log(error)

    }
  }

  return loading ? (
    <div className="spinner-container">
      <SyncLoader loading size={50} color="#46a5de" />
    </div>
  ) : (
    <div>
      <div className="flex flex-row align-middle gap-2 items-center text-center content-center">
        <SnackbarProvider />
        <label className="">Quiz ID</label>
        <input
          value={quizId}
          onChange={(e) => setQuizId(e.target.value)}
          type="text"
          className="question-inputs mb-0"
        />
        <button
          disabled={loading}
          onClick={getResults}
          className={`p-2 rounded-sm cursor-pointer shadow-sm shadow-black text-white hover:bg-green-300 ${
            loading ? "bg-green-200" : "bg-green-500"
          }`}
        >
          Get Results
        </button>
      </div>
      {results.length !== 0 && (
        <div className="mt-6 ">
          <div className="flex w-full text-3xl text-green-500">
            <h2 className="w-[50%]">Name</h2>
            <h2 className="w-[20%]">Score</h2>
            <h2 className="w-[30%]">Quiz Date</h2>
          </div>
          {results.map(({ name, score, createdAt }, id) => {
            const date = new Date(createdAt);
            return (
              <div key={id} className="flex gap-2 w-full border-2 mb-1">
                <h2 className="w-[50%]">{name}</h2>
                <h2 className="w-[20%] pl-4">{score}</h2>
                <h2 className="w-[30%]">{`${date.toLocaleString()}`}</h2>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
export default ViewResults