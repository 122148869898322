import { Button } from '@mui/material'
import React, { useState } from 'react'
import ResultDocument from '../components/ResultDocument'
import { PDFDownloadLink } from '@react-pdf/renderer'
import axios from 'axios'
import { useEffect } from 'react'
import { SyncLoader } from 'react-spinners'
import { BASE_URL } from '../data/data'

const Result = ({ score, questions, type, name, quizId, subject }) => {
  const [marking, setMarking] = useState(false);
  const [marks, setMarks] = useState({result:[]});

  useEffect(() => {
    setMarking(true)
    const markTrivia = async (submittedAnswers) => {
      try {
        const res = await axios.post(
          BASE_URL+"question/mark",
          submittedAnswers
        );
        console.log(res)
        if (res.status === 200) {
          setMarks(res.data);
          setMarking(false);
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        console.log(error);
        setMarking(false);
      }
    };

    const saveResult = async (submittedAnswers) => {
      try {
        const res = await axios.post(
          BASE_URL + "result/",
          submittedAnswers
        );
        if (res.status === 201) {
          setMarks(res.data);
          setMarking(false);
        } else {
          throw new Error(res.error);
        }
      } catch (error) {}
    };
    if (type==="setup") {
      saveResult({ name, quizId, submittedAnswers:score });
    } else {
      markTrivia(score);
    }
  }, [score, type, name, quizId]);

  return (marking) ? (
    <div className="spinner-container gap-2">
      {type === "setup" ?
        <h2>Submitting...</h2> :
        <h2>Marking...</h2>
      }
      <SyncLoader loading size={50} color="#46a5de" />
    </div>
  ) : type === "setup" ? (
    <div>
      <h2>Answers Submitted Successfully!!.</h2>  
    </div>
  ):(
    <div>
      Make sure to come back for another spin.
      <br />
      <br />
      <br />
      <br />
      <div id="print-score">
        Final Score: {marks.mark}/{score.length}
      </div>
      <br />
      <br />
      {/* <PrintQuiz /> */}
      <br />
      <br />
      <br />
      <br />
      <div>
        <PDFDownloadLink
            document={<ResultDocument marks={marks} options={questions.map(({options})=>options)} name={name} subject={subject} />}
          fileName="quiz-result.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                disabled
              >
                Loading Result...
              </Button>
            ) : (
              <Button variant="contained" size="medium" color="success">
                Download Result
              </Button>
            )
          }
        </PDFDownloadLink>
      </div>
      <br />
      <br />
      <br />
      <Button href="/" variant="contained" color="primary" size="medium">
        Take Another Quiz
      </Button>
    </div>
  );
};

export default Result