import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../data/data";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async(data) => {
    try {
      setLoading(true)
      const res = await axios.post(
        BASE_URL+"admin/login",
        data
      );
      if (res.status === 201 || res.status===200) {
        localStorage.setItem("userdata", JSON.stringify(res.data));
        navigate("/")
      } else {
        setLoading(false)
        enqueueSnackbar("Login Failed!!", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      setLoading(false)
      enqueueSnackbar("Login Failed!!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  const takeQuiz = () => {
    localStorage.setItem("userData", JSON.stringify({ "name": "test" }))
    navigate("/take-quiz")
  }

  return (
    <div className="root-layout bg-[#fffffff] sm:min-h-[100vh] sm:min-w-[750px] flex flex-col w-full">
      <h1 className="sm:ml-0 text-5xl italic font-extrabold mb-4">
        Skoolbod Quiz
      </h1>
      <div className="">
        Dont have an account?{" "}
        <button
          className="text-red-400 ml-2 italic"
          onClick={() => navigate("/sign-up")}
        >
          SignUp
        </button>
      </div>
      <div>
      <button onClick={takeQuiz} className="text-red-400 mt-2 mb-2 italic cursor-pointer">Take Quiz With ID</button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-4">
        <div>
          <SnackbarProvider />
        </div>
        {/* register your input into the hook by invoking the "register" function */}
        <label>Email</label>
        <input
          className="question-inputs"
          type="email"
          {...register("email", { required: "Email Address is required" })}
          aria-invalid={errors.email ? "true" : "false"}
        />

        <label>Password</label>
        <input
          className="question-inputs"
          type="password"
          placeholder="* * * * * * * *"
          {...register("password", { required: "Password is required" })}
          aria-invalid={errors.password ? "true" : "false"}
        />
        {/* errors will return when field validation fails  */}
        {(errors.password || errors.email) && (
          <span className="text-red-600">
            Email and Password are required!!
          </span>
        )}

        <button
          disabled={loading}
          className={`p-2 mt-4 rounded-sm cursor-pointer shadow-sm shadow-black text-white relative hover:bg-green-300 ${
            loading ? "bg-green-200 hover:cursor-wait" : "bg-green-500"
          }`}
        >
          {!loading ? "Login" : "Loginin...."}
        </button>
      </form>
    </div>
  );
}
export default Login