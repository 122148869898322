import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import he from 'he';
import { optionsMap } from "../data/data";
import Result from "../pages/Result";
import { SnackbarProvider } from "notistack";
import Timer from "../pages/Timer";

const dataConv = {0:"A", 1:"B", 2:"C", 3:"D"}

const Question = ({
  questions,
  name, type, 
  quizId,
  subject
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [selectedOption, setSelectedOption] = useState(null);
  const [warning, setWarning] = useState(false);
  const [submittedOptions, setSubmittedOptions] = useState([]);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    setSubmittedOptions(questions.map(({ _id }) => {
      return {
        _id, submittedAnswer:null
      }
    }))
  },[questions])

  const choiceHandler = (value) => {
    if (submittedOptions[currentQuestion]) {
      addAnswer(currentQuestion, {})
    }
    if (selectedOption === value) {
      setSelectedOption(null); // Unselect the choice if it was already selected
    } else {
      setSelectedOption(optionsMap[value]); // Select the new choice
    }
    setWarning(false);
  };

  const addAnswer = (questionIndex, answer) => {
    const submittedOptionsCopy = [...submittedOptions]
    submittedOptionsCopy[questionIndex] = answer;
    setSubmittedOptions(submittedOptionsCopy)
    setSelectedOption(null)
  }

  const nextQuestionHandler = () => {
    if (currentQuestion === questions.length - 1 ) {
      addAnswer(currentQuestion, {
        _id: questions[currentQuestion]._id,
        submittedAnswer: selectedOption,
      });
      // const checkOptions = [...submittedOptions, {
      //   _id: questions[currentQuestion]._id,
      //   submittedAnswer: selectedOption,
      // }]
      // if (
      //   checkOptions.filter(({ submittedAnswer }) => submittedAnswer == null)
      //     .length > 0
      // ) {
      //   enqueueSnackbar("Some Questions Are not Answered!!", {
      //     variant: "error",
      //     autoHideDuration: 4000,
      //   });
      // } else {
        setShowResult(true);
        window.history.replaceState({}, "");
      // }
    } else {
      setWarning(false);

      setSelectedOption(null);
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      if (selectedOption) {
        addAnswer(currentQuestion, {
          _id: questions[currentQuestion]._id,
          submittedAnswer: selectedOption,
        });
      }
    } 
  };

  const prevQuestionHandler = () => {
    if (currentQuestion === 0) {
      return;
    }
    if (selectedOption) {
      addAnswer(currentQuestion, {
        _id: questions[currentQuestion]._id,
        submittedAnswer: selectedOption,
      });
    }
    setCurrentQuestion((prevQuestion) => prevQuestion - 1);
    setSelectedOption(null);
  }

  useEffect(() => {
    setSelectedOption(null);
    setWarning(false);
  }, [currentQuestion]);
  return showResult ? (
    <Result
      score={submittedOptions}
      questions={questions}
      name={name}
      type={type}
      quizId={quizId}
      subject={subject}
    />
  ) : (
    <div className="">
      <div>
        <Timer time={5} setShowResult={setShowResult} showResult={showResult} />
      </div>
      <div>
        <SnackbarProvider />
      </div>
      <h1 className="current-question__number">
        Question {currentQuestion + 1}
      </h1>
      <div className="current_question__container">
        {questions[currentQuestion].images && (
          <div className="grid grid-cols-2 gap-3 mb-5">
            {questions[currentQuestion].images.map((img, id) => {
              return (
                <div key={id} className="flex flex-col gap-2">
                  <h2>Option {dataConv[id]}:</h2>
                  <img src={img} alt="choice" width={200} height={200}/>
                </div>
              );
            })}
          </div>
        )}
        <h2 className="current_question__item font-extrabold text-2xl">
          {he.decode(questions[currentQuestion].question)}
        </h2>
        {warning && <p key={1}>{warning}</p>}
      </div>
      <div className="choices_container mt-3">
        {questions[currentQuestion].options &&
          questions[currentQuestion].options.map((value, id) => {
            return (
              <div className="flex flex-row w-full items-center gap-1">
                <label className="text-[#21405a]">{optionsMap[id]}: </label>
                <button
                  key={value}
                  className={`p-2 text-xl border-2 border-[#42a5f5] mt-4 rounded-md w-[90%] ${
                    selectedOption === optionsMap[id] ||
                    (submittedOptions[currentQuestion] &&
                      submittedOptions[currentQuestion]["submittedAnswer"] ===
                        optionsMap[id])
                      ? "selected-button"
                      : ""
                  }`}
                  onClick={() => choiceHandler(id)}
                >
                  {he.decode(value)}
                </button>
              </div>
            );
          })}
      </div>
      <div className="current-question__next-button gap-4">
        <Button
          onClick={prevQuestionHandler}
          variant="contained"
          color="primary"
          disabled={currentQuestion === 0}
          style={{ marginTop: "20px", borderRadius: "15px", width: "80px" }}
        >
          Previous
        </Button>
        <Button
          onClick={nextQuestionHandler}
          variant="contained"
          color="primary"
          style={{ width: 250, marginTop: "20px", borderRadius: "15px" }}
        >
          {currentQuestion === questions.length - 1 ? "Submit" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default Question;