import React from 'react'
import { useRouteError, Link } from 'react-router-dom'

const ErrorLayout = () => {
    const error = useRouteError()
  return (
    <div>
        <h2>Oops, An error occurred!</h2>
        <p>{error.message}</p>
        <p> Go to <Link to='/'>Homepage</Link></p>
    </div>
  )
}

export default ErrorLayout