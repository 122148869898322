import { useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { BASE_URL } from "../data/data";
import Categories from "../data/data";

export default function CreateQuiz() {
  const [loading, setLoading] = useState(false);
  const [created, setCreated] = useState(false);
  const [id, setId] = useState("");


  const onCopyText = () => {
    enqueueSnackbar("Copied to clipboard!!", {
      variant: "success",
      autoHideDuration: 2000,
    });// Reset status after 2 seconds
  };
  
  const {
    register,
    handleSubmit,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true)
    const res = await axios.post(
      BASE_URL+"quiz",
      data
    );
    const quizData = await res.data;
    if (res.status===201) {
      setCreated(true)
      setId(quizData.quiz._id)
      setLoading(false)
    }
  };

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <>
      <div>
        <SnackbarProvider />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-6 flex flex-col">
        {/* register your input into the hook by invoking the "register" function */}
        <label className="mt-3">Title</label>
        <input
          {...register("title", { required: true })}
          className="question-inputs"
        />
        <label className="mt-3">Number of Questions</label>
        <input
          defaultValue="10"
          type="number"
          min={5}
          {...register("numQuestions", { required: true })}
          className="question-inputs"
        />
        <label className="mt-3">Due Date</label>
        <input
          type="date"
          {...register("dueDate")}
          className="question-inputs"
        />
        <label className="flex gap-4 items-center">
          Subject
          <select
            defaultValue="Chemistry"
            {...register("subject", { required: true })}
            className="ml-2 border border-gray-400 rounded-md p-2"
          >
            {Categories.map(({ category, value }, id) => (
              <option key={id} value={value}>
                {category}
              </option>
            ))}
          </select>
        </label>
        <label className="flex gap-4 align-middle mt-3 items-center">
          Difficulty Level
          <select
            defaultValue="Easy"
            {...register("difficulty", { required: true })}
            className="ml-2 border border-gray-400 rounded-md p-2"
          >
            <option value="Easy">Easy</option>
            <option value="Medium">Medium</option>
            <option value="Hard">Hard</option>
          </select>
        </label>

        <button
          type="submit"
          className={`p-2 mt-4 rounded-sm cursor-pointer shadow-sm shadow-black text-white relative hover:bg-green-300 ${
            loading ? "bg-green-200" : "bg-green-500"
          }`}
        >
          {loading ? "Creating..." : "Create"}{" "}
        </button>
      </form>
      {created && (
        <div className="mt-6 flex flex-col">
          <h2>
            Quiz Created Successfully, copy the generated id and share it to
            participants
          </h2>
          <div>
            ID: {id}
            <CopyToClipboard text={id} onCopy={onCopyText}>
              <button className="ml-2 text-green-500">
                <FaRegCopy />
              </button>
            </CopyToClipboard>
          </div>
        </div>
      )}
    </>
  );
}
