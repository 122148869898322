import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography'
import Categories, { Difficulty } from "../data/data";

const Home = () => {
  const [category, setCategory] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [name, setName] = useState("");

  const navigate = useNavigate();

  const nameChangeHandler = (e) => {
    setName(e.target.value);
  };

  const subjectChangeHandler = (e) => {
    setCategory(e.target.value);
  };

  const difficultyHandler = (e) => {
    setDifficulty(e.target.value);
  };

  const submitHandler = () => {
    if (category && difficulty) {
      navigate(`/quiz`, {state:{category, difficulty, name, limit:10}});
    } else {
      throw new Error("Category and Difficulty not selected");
    }
  };

  return (
    <div className="w-[90%]">
      <div className="quiz-rules">
        <Typography variant="h5">
          Quiz Rules.
        </Typography>
      </div>

      <div className="quiz-rules__items">
      <Typography variant="h5">
        1. You will be given 10 questions to answer in this quiz.
      </Typography>
      <Typography variant="h5">
        2. You will be awarded 1 point for selecting the correct answer.
      </Typography>
      <Typography variant="h5">
        3. Each question has four options, you can only choose one answer.
      </Typography>
      <Typography variant="h5">
        4. You will be able to see your score at the end of the quiz.
      </Typography>
      </div>
      
      <div className="inputs">
        <TextField
          className="inputs-name"
          label="Enter Your Name"
          InputLabelProps={{
            style: {
              fontSize: "18px", 
              fontWeight: "bold",
            },
          }}
          variant="standard"
          onChange={nameChangeHandler}
          value={name}
        />
        <br/>
        <TextField
          className="inputs-subject"
          label="Category"
          select
          variant="standard"
          InputLabelProps={{
            style: {
              fontSize: "18px", 
              fontWeight: "bold",
            },
          }}
          helperText="Please select a subject"
          FormHelperTextProps={{
            style: {
              fontSize: "15px"
            }
          }}
          onChange={subjectChangeHandler}
          value={category}
        >
          {Categories.map((option) => (
            <MenuItem key={option.category} value={option.value}>
              {option.category}
            </MenuItem>
          ))}
        </TextField>
        <br/>
        <TextField
          className="inputs-difficulty"
          label="Choose Difficulty"
          select
          variant="standard"
          InputLabelProps={{
            style: {
              fontSize: "18px", 
              fontWeight: "bold",
            },
          }}
          helperText="Please select a difficulty"
          FormHelperTextProps={{
            style: {
              fontSize: "15px"
            }
          }}
          onChange={difficultyHandler}
          value={difficulty}
        >
          {Difficulty.map((difficulty) => (
            <MenuItem key={difficulty.value} value={difficulty.label}>
              {difficulty.label}
            </MenuItem>
          ))}
        </TextField>
        <div className="button">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={submitHandler}
          sx={{ borderRadius: '10px',width: "180px", marginTop: "20px"}}
          >
          Start Quiz
        </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
