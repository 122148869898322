import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../data/data";

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    if (data.password !== data.cpassword) {
      enqueueSnackbar("Passwords Does Not Match!!", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      try {
        setLoading(true);
        const res = await axios.post(
         BASE_URL+"admin/signup",
          data
        );
        if (res.status === 201 || res.status === 200) {
          enqueueSnackbar("Registered Successfully!!", {
            variant: "success",
            autoHideDuration: 3000,
          });
          navigate("/login");
        } else {
          setLoading(false);
          enqueueSnackbar("Signup Failed!!", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        if (error.name === "AxiosError") {
          const message = error.response.data
          setLoading(false);
          enqueueSnackbar(`Sign-up failed: ${message.error}`, {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else {
          setLoading(false);
          enqueueSnackbar("Signup Failed!!", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }
    }
  };

  return (
    <div className="root-layout bg-[#fffffff] sm:min-h-[100vh] sm:min-w-[750px] flex flex-col w-full">
      <h1 className="sm:ml-0 text-5xl italic font-extrabold mb-4">
        Skoolbod Quiz
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full">
        <div>
          <SnackbarProvider />
        </div>
        {/* register your input into the hook by invoking the "register" function */}
        <label>Name</label>
        <input
          className="question-inputs"
          {...register("username", { required: "Name is required" })}
          aria-invalid={errors.username ? "true" : "false"}
        />
        <label>Email</label>
        <input
          className="question-inputs"
          type="email"
          placeholder="mail@mail.com"
          {...register("email", { required: "Email Address is required" })}
          aria-invalid={errors.email ? "true" : "false"}
        />

        <label>Password</label>
        <input
          className="question-inputs"
          type="password"
          placeholder="* * * * * * * *"
          {...register("password", { required: "Password is required" })}
          aria-invalid={errors.password ? "true" : "false"}
        />

        <label>Confirm Password</label>
        <input
          className="question-inputs"
          type="password"
          placeholder="* * * * * * * *"
          {...register("cpassword", { required: "Password is required" })}
          aria-invalid={errors.cpassword ? "true" : "false"}
        />
        {/* errors will return when field validation fails  */}
        {(errors.password || errors.email || errors.username || errors.cpassword) && (
          <span className="text-red-600">All fields are required!!</span>
        )}

        <button
          disabled={loading}
          className={`p-2 mt-4 rounded-sm cursor-pointer shadow-sm shadow-black text-white relative hover:bg-green-300 ${
            loading ? "bg-green-200 hover:cursor-wait" : "bg-green-500"
          }`}
        >
          {!loading ? "Register" : "Registering...."}
        </button>
      </form>
    </div>
  );
};
export default SignUp;
