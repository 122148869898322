import { PDFViewer } from "@react-pdf/renderer"
import ResultDocument from "../components/ResultDocument"

const Render = () => {
  return (
    <div className="">
      <h2>Musa </h2>
      <PDFViewer style={{width:"700px", height:"800px"}}>
        <ResultDocument />
      </PDFViewer>
    </div>
  );
}
export default Render