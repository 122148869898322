import {kQuestions} from "../data/data"
import { useState } from "react";
import Question from "../components/Questions";


const Montessori = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [choice, setChoice] = useState([]);
  const [score, setScore] = useState(0);
  return (
    <div className="quiz">
      {
        kQuestions.length > 0 && ( // Check if loading is false and questions are available
          <>
            <div>
              <h3 className="quiz-category">
                {kQuestions[currentQuestion].category}
              </h3>
            </div>
            <Question
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              questions={kQuestions}
              type={"nml"}
              name={"Gabriel Ab"}
              quizId={"1234567"}
              choices={choice}
              score={score}
              setScore={setScore}
              correct_answer={kQuestions[currentQuestion]?.correct_answer}
              subject={"Montessori"}
            />
          </>
        )}
      {/* {loading && (
        <div className="spinner-container">
          <SyncLoader loading size={50} color="#46a5de" />
        </div>
      )} */}
    </div>
  );
}
export default Montessori